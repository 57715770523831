<script src="../../../../../../../yilanyun/newservice_manager3.0/evui/src/config/setting.js"></script>
<template>
	<div class="ele-body">
		<div style="height: 166px;" v-if="earningsRecord.is_view !== 0">
			<el-row :gutter="20">
				<el-col :span="8">
					<el-card shadow="never" class="grid-content bg-purple">
						<div class="d-flex smsInner">
							<div class="blueLine">|</div>
							<div>本月订单服务费提点收益（元）</div>
						</div>
						<div style="margin: 32px 0 0 20px;font-size: 34px;"><img src="./money.png"
								style="margin-right: 10px;" />{{ earningsRecord.month }}</div>
					</el-card>
				</el-col>
				<el-col :span="8">
					<el-card shadow="never" class="grid-content bg-purple">
						<div class="d-flex smsInner">
							<div class="blueLine">|</div>
							<div>本周订单服务费提点收益（元）</div>
						</div>
						<div style="margin: 32px 0 0 20px;font-size: 34px;"><img src="./money.png"
								style="margin-right: 10px;" />{{ earningsRecord.week }}</div>
					</el-card>
				</el-col>
				<el-col :span="8">
					<el-card shadow="never" class="grid-content bg-purple">
						<div class="d-flex smsInner">
							<div class="blueLine">|</div>
							<div>今日订单服务费提点收益（元）</div>
						</div>
						<div style="margin: 32px 0 0 20px;font-size: 34px;"><img src="./money.png"
								style="margin-right: 10px;" />{{ earningsRecord.day }}</div>
					</el-card>
				</el-col>
			</el-row>
		</div>

		<el-card shadow="never">
			<!-- 搜索表单 -->
			<el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
				@submit.native.prevent>
				<el-form-item label="订单状态:" label-width="80px">
					<el-select v-model="table.where.status" placeholder="请选择" clearable class="ele-fluid">
						<el-option label="全部" value="1" />
						<el-option label="待接单" value="2" />
						<el-option label="进行中" value="3" />
						<el-option label="已完成" value="4" />
						<el-option label="已失效" value="5" />
						<el-option label="乘客取消" value="6" />
						<el-option label="司机取消" value="7" />
						<el-option label="手动结束" value="8" />
						<el-option label="违约金" value="9" />
						<el-option label="空驶补偿" value="10" />
						<el-option label="待支付" value="11" />
					</el-select>
				</el-form-item>
				<el-form-item label="订单类型:" label-width="80px">
					<el-select v-model="table.where.type" placeholder="请选择" clearable class="ele-fluid">
						<el-option label="全部" value="0" />
						<el-option label="就近叫单" value="1" />
						<el-option label="指定叫单" value="3" />
						<el-option label="预约单" value="5" />
					</el-select>
				</el-form-item>
				<el-form-item label="订单标记:" label-width="80px">
					<el-select v-model="table.where.sign" placeholder="请选择" clearable class="ele-fluid">
						<el-option label="全部" value="10" />
						<el-option label="爱心订单" value="1000" />
						<el-option label="学校订单" value="2000" />
						<el-option label="普通订单" value="3000" />
					</el-select>
				</el-form-item>
				<el-form-item label="订单号:" label-width="70px" style="margin: 0 10px 0 0">
					<el-input v-model="table.where.order_id" placeholder="请输入订单编号" clearable />
				</el-form-item>
				<el-form-item label="用户手机号:" label-width="90px">
					<el-input v-model="table.where.user_phone" placeholder="请输入用户手机号" clearable />
				</el-form-item>
				<el-form-item label="司机姓名:" label-width="80px">
					<el-input v-model="table.where.driver_name" placeholder="请输入司机姓名" clearable />
				</el-form-item>
				<el-form-item label="司机手机号:" label-width="90px">
					<el-input v-model="table.where.driver_phone" placeholder="请输入司机手机号" clearable />
				</el-form-item>
				<el-form-item label="车牌号:" label-width="90px">
					<el-input v-model="table.where.driver_carnumber" placeholder="请输入车牌号" clearable />
				</el-form-item>
				<el-form-item label="省:" label-width="35px" class="w-170">
					<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder='请选择省'
						clearable>
						<el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市:" label-width="35px" class="w-150">
					<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid)" placeholder='请选择市'
						clearable>
						<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="县/区:" label-width="62px" class="w-200">
					<el-select v-model="table.where.aid" placeholder='请选择县/区' @change="$forceUpdate()" clearable>
						<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
							:label="option.name"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="订单时间："
					style="display: flex ; align-items: center; padding-left: 10px; margin-right: 10px;">
					<el-date-picker v-model="value" @change="birthdayChange" type="datetimerange"
						value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期"
						end-placeholder="结束日期" :default-time="['00:00:00']">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon">
						搜索</el-button>
					<el-button @click="reset_search()">重置</el-button>
				</el-form-item>
				<el-form-item>
					<download style="margin-left: 0" class="ele-btn-icon ml-20" label="导出" url="order/exportExcelOrder"
						:params="exportOrderParams()" :outtime='6000' v-if="permission.includes('sys: callList:export')"
						ref="childDownload" />
				</el-form-item>
			</el-form>

			<!-- 数据表格 -->
			<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
				highlight-current-row :cell-class-name="nopaddingCell" :stripe=true>
				<template slot-scope="{index}">
					<el-table-column type="selection" width="45" align="center" fixed="left" />
					<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />
					<el-table-column prop="order_id" label="订单号" show-overflow-tooltip min-width="180" />
					<el-table-column prop="order_address" label="所属地区" show-overflow-tooltip min-width="150" />
					<el-table-column prop="channel_name" label="订单类型" show-overflow-tooltip min-width="100"/>
				<!-- 	<el-table-column prop="cf_time" label="出发时间" show-overflow-tooltip min-width="150"/> -->
					<el-table-column prop="user_name" label="用户昵称" show-overflow-tooltip min-width="140" />
					<el-table-column prop="user_phone" label="用户手机号" show-overflow-tooltip min-width="120" />
					<el-table-column label="下单时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.create_time * 1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column prop="cn_platform" label="下单方式" show-overflow-tooltip min-width="80" />
					<!-- <el-table-column label="计划出发时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.create_time * 1000 | toDateString }}</template>
					</el-table-column> -->
					<el-table-column prop="place_address_title" label="出发地" show-overflow-tooltip min-width="200" />
					<el-table-column prop="target_address_title" label="目的地" show-overflow-tooltip min-width="200" />
					<el-table-column label="接单时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.receive_time * 1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column prop="driver_name" label="接单司机" show-overflow-tooltip min-width="120" />
					<el-table-column prop="driver_phone" label="司机手机号" show-overflow-tooltip min-width="120" />
					<el-table-column prop="car_number" label="车牌号" show-overflow-tooltip min-width="100" />
					<el-table-column prop="grab_position_title" label="接单位置" show-overflow-tooltip min-width="200" />
					<el-table-column label="行程开始时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.strat_car_time * 1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column label="行程结束时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.end_car_time * 1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column label="订单取消时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.cancel_time * 1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column label="订单状态" show-overflow-tooltip min-width="120">
						<template slot-scope="{row}">
							<div class="status">{{ row.status_name }}</div>
						</template>
					</el-table-column>
					<el-table-column prop="push_type" label="订单标记" show-overflow-tooltip min-width="120">
						<template slot-scope="{row}">{{ row.push_type == 0 ? '普通订单' : (row.push_type == 1 ? '爱心订单' :
							(row.push_type == 2 ? '学校订单' : '')) }}</template>
					</el-table-column>
					<el-table-column prop="order_price" label="订单金额(元)" show-overflow-tooltip min-width="120" />
					<el-table-column prop="driver_get_money" label="实际到账(元)" show-overflow-tooltip min-width="120" />
					<el-table-column prop="handling_fee" label="手续费(元)" show-overflow-tooltip min-width="120" />
					<el-table-column prop="payment_name" label="支付方式" show-overflow-tooltip min-width="120">
						<!--						<template slot-scope="{row}">{{ (row.status == 10 ?-->
						<!--							(row.payment == 1 ? '微信' : row.payment == 2 ? '支付宝' : row.payment == 5 ? '云闪付' : '线下支付') : '/')-->
						<!--						}}</template>-->
					</el-table-column>
					<el-table-column prop="contact_passengers_price" label="空驶补偿(元)" show-overflow-tooltip min-width="120">
						<template slot-scope="{row}">{{ row.contact_passengers_price == null ? '/' :
							row.contact_passengers_price }}</template>
					</el-table-column>
					<el-table-column prop="star" label="乘客评分" show-overflow-tooltip min-width="120" />
					<el-table-column label="操作" show-overflow-tooltip min-width="120">
						<template slot-scope="{row}">
							<el-link
								v-if="((row.status !== 2 && row.status !== 10 && row.status !== 101) || row.has_cancel_order_info) && permission.includes('sys:order:order_finish')"
								@click="overOrder(row)" icon="el-icon-s-claim" type="primary"
								:underline="false">手动结束</el-link>
						</template>
					</el-table-column>
					<el-table-column prop="admin_name" label="手动结束账号" show-overflow-tooltip min-width="120" />
					<el-table-column prop="finish" label="结束原因" show-overflow-tooltip min-width="120" />
					<el-table-column label="更多" width="270px" align="center" :resizable="false" fixed="right">
						<template slot-scope="{row}">
							<el-link @click="handlecheck(row)" icon="el-icon-view" type="primary" :underline="false"
								v-if="permission.includes('sys:order:view')">完整定位</el-link>
							<el-link v-if="permission.includes('sys:order:online_log')" @click="handleChat(row)"
								icon="el-icon-notebook-2" type="primary" :underline="false">聊天记录
							</el-link>
							<el-link
								v-if="permission.includes('sys:order:addz') && ((row.status_name.indexOf('已完成') != -1 && row.driver_id) || row.status_name.indexOf('进行中') != -1 || row.status_name.indexOf('空驶补偿') != -1)"
								@click="viewTrack(row)" icon="el-icon-location-information" type="primary"
								:underline="false">查看轨迹
							</el-link>
						</template>
					</el-table-column>
				</template>
			</ele-data-table>
		</el-card>

		<el-dialog v-dialogDrag title="查看订单详情" :visible.sync="dialogTableVisibleCheck" :destroy-on-close="true"
			:lock-scroll="false">
			<el-card shadow="never">
				<el-form :model="checkForm" label-width="140px">
					<el-form-item label="出发地：">
						{{ checkForm.place_address_title ? '【' + checkForm.place_address_title + '】' +
							checkForm.place_address : checkForm.place_address }}
					</el-form-item>
					<el-form-item label="目的地：">
						{{ checkForm.target_address_title ? '【' + checkForm.target_address_title + '】' +
							checkForm.target_address : checkForm.real_offcar_address }}
					</el-form-item>
					<el-form-item label="接单位置：">
						{{ checkForm.grab_driver_address }}
					</el-form-item>
					<el-form-item label="结束位置：">
						{{ checkForm.real_offcar_address }}
					</el-form-item>
				</el-form>
			</el-card>
		</el-dialog>
		<!-- 手动结束弹窗 -->
		<el-dialog v-dialogDrag title="确定手动结束" :visible.sync="overOrderShow" :destroy-on-close="true" :lock-scroll="false">
			<el-form :model="overOrderList" ref="overOrderList" @submit.native.prevent :rules="editRules"
				style="margin: 10px 0;">
				<el-form-item label="手动结束原因:" label-width="120px">
					<el-input v-model="finish" placeholder="手动结束原因" style="width: 450px;" />
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button @click="overOrderShow = false">取消</el-button>
				<el-button type="primary" @click="order_status_update(overOrderList)">确认</el-button>
			</div>
		</el-dialog>
		<!-- 聊天记录 -->
		<el-dialog v-dialogDrag title="聊天记录" :visible.sync="dialogTableVisibleChatRecord" class="records"
			@close='closeDialog' :destroy-on-close="true" :lock-scroll="false" width="600px">
			<div class="charRecord-box">
				<div class="driverBox" style="padding:20px;width: 500px;">
					<div class="" style="width:80px;height:80px;">
						<img style="width:60px;height:60px;border-radius:50%" :src="driverInfo.headimg" />
					</div>
					<div class="">
						<div class="" style="font-size:17px;margin:5px 0">{{ driverInfo.name }}</div>
						<div class="" style="font-size:13px">{{ driverInfo.car_number }}</div>
					</div>
				</div>
				<div>
					<div class="charRecord-inner" v-for="(option, index) in charList" :key="option.id">
						<!-- 时间 -->
						<!-- <div class="w-100" v-if="option.isTimeShow==1 && option.p_show==1 && option.type !== 33 && option.type !== 34"> -->
						<div class="w-100"
							v-if="option.p_show == 1 && option.type !== 33 && option.type !== 34 && option.content.S != 89 && option.content.S != 38 && option.content.S != 94 && option.content.S != 27">
							<div class="charRecord-time">{{ option.create_time }}</div>
						</div>
						<!-- 系统消息-->
						<div class="w-100"
							v-if="(option.content.S == 5 || option.content.S == 100 || option.content.S == 1 || option.content.S == 20 || option.content.S == 39) && option.p_show == 1">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" src="./logo1.png">
								</div>
								<div class="hh"></div>
								<div class="charRecord-title5 p-10">{{ option.content.M }}</div>
							</div>
						</div>
						<!-- 左侧 完善目的地-->
						<div class="w-100" v-if="option.content.S == 27 && option.direction == 3">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" src="./logo1.png">
								</div>
								<div class="hh"></div>
								<div class="charRecord-title ">
									<div class="">
										<div class="m-10 mb-0">您可以完善一下目的地</div>
										<div class="whiteAddress">{{ orderInfo.place_address }}</div>
										<div class="addressBtn">
											<span class="ignore">忽略</span>
											<span class="submit">提交</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- 右侧 完善目的地-->
						<div class="w-100" v-if="option.content.S == 27 && option.direction == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 ">
									<div class="">
										<div class="m-10 mb-0">您可以完善一下目的地</div>
										<div class="whiteAddress">请输入您要去的地址</div>
										<div class="addressBtn">
											<span class="ignore" style="width: 100%;border-right: none">已忽略</span>
										</div>
									</div>
								</div>
								<div class="rhh"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!--						右文字信息-->
						<div class="w-100" v-if="option.content.S == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10">{{ option.content.M }}</div>
								<div class="rhh"></div>
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 左侧 语音-->
						<div class="w-100 " v-if="option.content.S == 21 && option.direction == 2">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>
								<div class="charRecord-title2 p-10 " :style="{ width: option.bgWidth }"
									@click="handlePlay(option.content.P.URL, option.id, index)"
									v-if="option.isPlay == true">
									<audio class="pasuVideo" :src="option.content.P.URL"
										:ref="'middle' + index">您的浏览器不支持</audio>
									<div class="lvoide1">
										<img src="../../../assets/images/ic_b_yuyin@2x.png">
									</div>
									<div>{{ option.content.P.LONG }}''</div>
								</div>
								<div class="charRecord-title2 p-10" :style="{ width: option.bgWidth }"
									v-if="option.isPlay == false">
									<audio class="pasuVideo" :src="option.content.P.URL"
										:ref="'middle' + index">您的浏览器不支持</audio>
									<div class="lvoide1">
										<img src="../../../assets/images/ic_b_yuyin.gif">
									</div>
									<div>{{ option.content.P.LONG }}''</div>
								</div>
							</div>
						</div>
						<!-- 右侧 语音-->
						<div class="w-100 " v-if="option.content.S == 21 && option.direction == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 bgGreen " :style="{ width: option.bgWidth }"
									@click="handlePlay(option.content.P.URL, option.id, index)"
									v-if="option.isPlay == true">
									<audio class="pasuVideo" :src="option.content.P.URL"
										:ref="'middle' + index">您的浏览器不支持</audio>
									<div style="color:#fff">{{ option.content.P.LONG }}''</div>
									<div class="rvoide1">
										<img src="../../../assets/images/yuyin-ico.png" :ref="'changeImg' + index">
									</div>

								</div>
								<div class="charRecord-title2 p-10 bgGreen" :style="{ width: option.bgWidth }"
									v-if="option.isPlay == false">
									<audio class="pasuVideo" :src="option.content.P.URL"
										:ref="'middle' + index">您的浏览器不支持</audio>

									<div style="color:#fff">{{ option.content.P.LONG }}''</div>
									<div class="rvoide1">
										<img src="../../../assets/images/yuyin-gif.gif">
									</div>

								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 图片-->
						<div class="w-100" v-if="option.content.S == 22">
							<div class="charRecord-rightItem">
								<div class="charRecord-title1 p-10" @click="hanlePicture(option.content.P.URL)">
									<img class="" style="max-width: 100px;" :src="option.content.P.URL">
								</div>
								<div class="rhh"></div>
								<div class="charRecord-image">
									<img width='100px' height='100px' class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 视频-->
						<div class="w-100" v-if="option.content.S == 6">
							<div class="charRecord-rightItem">
								<div class="charRecord-title1 p-10">
									<video width="100" height="100" controls>
										<source :src="option.content.P.URL" type="video/mp4">
									</video>
								</div>
								<div class="rhh"></div>
								<div class="charRecord-image">

									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 地图-->
						<div class="w-100" v-if="option.content.S == 26 && option.direction == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-titleMap p-10">
									<div class="amap-page-container">
										<div>{{ option.content.P.ADDRESS }}</div>
										<div>{{ option.content.P.TADDRESS }}</div>
										<div :style="{ width: '100%', height: '180px' }">
											<!-- vid="amapDemo1" -->

											<el-amap ref="map" :zoom="zoom" :center="option.content.P.POINT">
												<el-amap-marker></el-amap-marker>
											</el-amap>
										</div>
									</div>
								</div>
								<div class="rhh"></div>
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 左侧 地图-->
						<div class="w-100" v-if="option.content.S == 26 && option.direction == 2">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>
								<div class="charRecord-titleMap p-10">
									<div class="amap-page-container">
										<div :style="{ width: '100%', height: '180px' }">
											<el-amap ref="map" :zoom="zoom" :center="option.content.P.POINT">
												<el-amap-marker></el-amap-marker>
											</el-amap>
										</div>
									</div>
								</div>


							</div>
						</div>

						<!-- 左侧 语音通话 对方已取消-->
						<div class="w-100"
							v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 3 && option.p_show == 1">
							<div class="charRecord-leftItem">
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>

								<div class="charRecord-title2 p-10 disCenter voide">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone3grey@2x.png">
									</div>
									<div>{{ option.content.P.voiceCallMessage }}</div>
								</div>
							</div>
						</div>
						<!-- 左侧 语音通话 已拒接-->
						<div class="w-100"
							v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 4 && option.p_show == 1">
							<div class="charRecord-leftItem">
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>

								<div class="charRecord-title2 p-10 disCenter voide">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone3grey@2x.png">
									</div>
									<div>{{ option.content.P.message }}</div>
								</div>
							</div>
						</div>
						<!-- 左侧 语音通话 未接听-->
						<div class="w-100"
							v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 5 && option.p_show == 1">
							<div class="charRecord-leftItem">
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>

								<div class="charRecord-title2 p-10 disCenter voide">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/call_ic_blackwait.png">
									</div>
									<div>{{ option.content.P.voiceCallMessage }}</div>
								</div>
							</div>
						</div>
						<!-- 左侧 语音通话 通话时长-->
						<div class="w-100"
							v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 6">
							<div class="charRecord-leftItem">
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>

								<div class="charRecord-title2 p-10 disCenter voide">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phonegrey@2x.png">
									</div>
									<div>{{ option.content.P.message }}</div>
								</div>
							</div>
						</div>
						<!-- 右侧 语音通话 已取消-->
						<div class="w-100"
							v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 3 && option.p_show == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 disCenter voide bgGreen">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone3@2x.png">
									</div>
									<div>{{ option.content.P.message }}</div>
								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 语音通话 对方已拒接-->
						<div class="w-100"
							v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 4 && option.p_show == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 disCenter voide bgGreen">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone3@2x.png">
									</div>
									<div>{{ option.content.P.voiceCallMessage }}</div>
								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 语音通话 对方无应答-->
						<div class="w-100"
							v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 5 && option.p_show == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 disCenter voide bgGreen">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/call_ic_whitewait.png">
									</div>
									<div>{{ option.content.P.message }}</div>
								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 语音通话 通话时长-->
						<div class="w-100"
							v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 6">
							<!--							v-if="option.content.S==60 && option.direction==1 && option.content.P.call_status==6 && option.p_show==1">-->
							<div class="charRecord-rightItem">
								<div class="charRecord-title2 p-10 disCenter voide bgGreen">
									<div class=" mr-10">
										<img src="../../../assets/images/voice/ic_phone@2x.png">
									</div>
									<div>{{ option.content.P.message }}</div>
								</div>
								<div class="rhh1"></div>
								<div class="charRecordrightImage">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 左侧 订单账单-->
						<div class="w-100" v-if="option.content.S == 31">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" src="./logo1.png" />
								</div>
								<div class="hh"></div>
								<div class="charRecord-Paytitle">
									<div class="w-30">
										<div v-if="option.content.R == 2">
											<div class="m-10 mb-0 text-center">空驶补偿账单</div>
										</div>
										<div v-else>
											<div class="m-10 mb-0 text-center">订单账单</div>
										</div>

										<div class="m-10 mb-0 text-center">
											<span class="price">{{ option.show_data.price }}</span>元
										</div>
										<div class="p-10 text-center payBox">
											<div class="payTitle">行程车费</div>
											<div class="payPrice">{{ option.show_data.price }}元</div>
										</div>
										<div class="p-10  payBox">
											<div class="payTitle">零钱抵现</div>
											<div class="payPrice"><span style="color: #FF9D00;">-{{ option.show_data.bonus
											}}</span>元</div>
										</div>
										<div class="p-10 payType">
											<div>
												<img src="../../../assets/images/share_ic_wechat@2x.png"
													style="float: left;" alt="" width="30px" height="30px;">
												<span class="wxPay">微信支付</span>
											</div>

											<img src="../../../assets/images/ic_arrow@2x.png"
												style="float: right;margin-top: 3px;" alt="" width="10px" height="20px;">

										</div>
										<!--										<div class="p-10 payType borderTop">-->
										<!--											<div>-->
										<!--												<img src="../../../assets/images/ic_chat_pt_pay_alipay.png"-->
										<!--													style="float: left;" alt="" width="30px" height="30px;">-->
										<!--												<span class="wxPay">支付宝支付</span>-->
										<!--											</div>-->

										<!--											<img src="../../../assets/images/ic_arrow@2x.png"-->
										<!--												style="float: right;margin-top: 3px;" alt="" width="10px"-->
										<!--												height="20px;">-->
										<!--										</div>-->
										<div class="p-10 payType borderTop">
											<div>
												<img src="../../../assets/images/ic_pay@2x.png" style="float: left;" alt=""
													width="30px" height="30px;">
												<span class="wxPay">线下支付</span>
											</div>

											<img src="../../../assets/images/ic_arrow@2x.png"
												style="float: right;margin-top: 3px;" alt="" width="10px" height="20px;">
										</div>


									</div>
								</div>

							</div>
						</div>
						<!-- 左侧 违约金账单-->
						<div class="w-100" v-if="option.content.S == 46">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" src="./logo1.png" />
								</div>
								<div class="hh"></div>
								<div class="charRecord-Paytitle">
									<div class="w-30">
										<div class="m-10 mb-0 text-center">违约金账单</div>
										<div class="m-10 mb-0 text-center">
											<span class="price">{{ option.content.P.PRICE }}</span>元
										</div>
										<div class="p-10 payType">
											<div>
												<img src="../../../assets/images/share_ic_wechat@2x.png"
													style="float: left;" alt="" width="30px" height="30px;">
												<span class="wxPay">微信支付</span>
											</div>

											<img src="../../../assets/images/ic_arrow@2x.png"
												style="float: right;margin-top: 3px;" alt="" width="10px" height="20px;">

										</div>

									</div>
								</div>

							</div>
						</div>
						<!-- 右侧 违约金支付成功-->
						<div class="w-100" v-if="option.content.S == 36 && option.p_show == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-Paytitle ">
									<div>
										<div class="paySuccess">支付成功</div>
										<div class="money">
											<span style="color:#84B625;font-size:25px;text-align:center">{{
												option.content.PRICE }}</span>元
										</div>
									</div>

								</div>
								<div class="rhh"></div>
								<div class="charRecordRightImage">
									<img class="charHeadimg" :src="option.user.headimg" />
								</div>

							</div>
						</div>
						<!-- 右侧 支付成功-->
						<div class="w-100" v-if="option.content.S == 32 && option.p_show == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-Paytitle ">
									<div>
										<div class="paySuccess">支付成功</div>
										<div class="money">
											<span style="color:#84B625;font-size:25px;text-align:center">{{
												option.content.PRICE }}</span>元
										</div>
										<div class="otherPay">红包另支付0元</div>
									</div>

								</div>
								<div class="rhh"></div>
								<div class="charRecordRightImage">
									<img class="charHeadimg" :src="option.user.headimg" />
								</div>

							</div>
						</div>

						<!-- 左侧 被动红包 -->
						<div class="w-100" v-if="option.content.S == 72">
							<div class="charRecord-leftItem">
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.driver.headimg">
								</div>
								<div class="hh"></div>
								<div class="charRecord-Paytitle">
									<div class="">
										<div class="text-align pbt-10">对话红包</div>
										<div class="bill-box">
											<div class="">红包金额</div>
											<div class=""><span>{{ option.content.P.PRICE }}</span>元</div>
										</div>
										<div class="btn">
											<div class="btn-ignore">驳回</div>
											<div class="line"></div>
											<div class="btn-ignore">发红包</div>
										</div>

									</div>
								</div>
							</div>
						</div>
						<!-- 右侧 被动红包-->
						<div class="w-100" v-if="option.content.S == 69">
							<div class="charRecord-rightItem">
								<div class="charRecord-Paytitle">
									<div class="">
										<div class="text-align pbt-10">对话红包</div>
										<div class="bill-box">
											<div class="">红包金额</div>
											<div class=""><span>0.01</span>元</div>
										</div>
										<div class="btn">
											<div class="btn-ignore text-align">已驳回</div>
										</div>
									</div>
								</div>
								<div class="rhh"></div>
								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 未领取主动红包-->
						<div class="w-100" v-if="option.content.S == 71 && option.content.H == 1">
							<div class="charRecord-rightItem">
								<div class="charRecord-title3">
									<img src="../../../assets/images/bg_hb1@2x.png"
										style="position:relative;width:100%;height:100%" />

									<div class="red-box" style="position:absolute;top:30px">
										<div>
											<img class="package" src="../../../assets/images/img_hb1@2x.png" />
										</div>
										<div class="">
											<div class="">主动红包，司机可领取</div>
											<div class="font--9">未领取</div>
										</div>
									</div>
									<div class="address-box" style="position:absolute;top:64px">
										<div class="">
											<img class="weizhi" src="../../../assets/images/hb_logo@2x.png" />
										</div>
										<div class="font--9">咱县打车</div>
									</div>
								</div>

								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
						<!-- 右侧 已领取主动红包-->
						<div class="w-100" v-if="option.content.S == 71 && option.content.H == 2">
							<div class="charRecord-rightItem">
								<div class="charRecord-title3">
									<img src="../../../assets/images/bg_hb2@2x.png"
										style="position:relative;width:100%;height:100%" />

									<div class="red-box" style="position:absolute;top:30px">
										<div>
											<img class="package" src="../../../assets/images/img_hb2@2x.png" />
										</div>
										<div class="">
											<div class="">主动红包，司机可领取</div>
											<div class="font--9">未领取</div>
										</div>
									</div>
									<div class="address-box" style="position:absolute;top:64px">
										<div class="">
											<img class="weizhi" src="../../../assets/images/hb_logo@2x.png" />
										</div>
										<div class="font--9">咱县打车</div>
									</div>
								</div>

								<div class="charRecord-image">
									<img class="charHeadimg" :src="option.user.headimg">
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="ampBox" :style="{ display: ampDisplay == true ? 'block' : 'none' }" @click="handleClose">
				<img :src="ampSrc">
			</div>
		</el-dialog>
		<!-- 查看轨迹 -->
		<el-dialog v-dialogDrag title="查看车辆行驶轨迹" :visible.sync="trackShow" @closed="editForm = {}" :destroy-on-close="true"
			:lock-scroll="false">
			<ElDialogMapTrack :options="options"></ElDialogMapTrack>
		</el-dialog>
	</div>
</template>
<!--<link rel="stylesheet" href="https://a.amap.com/jsapi_demos/static/demo-center/css/demo-center.css" />-->

<script src="https://webapi.amap.com/maps?v=1.4.13&key=645cd97a39f5c47bafce4a316785f342"></script>
<script src="https://a.amap.com/jsapi_demos/static/demo-center/js/demoutils.js"></script>
<script>
	import ElDialogMapTrack from '@/components/ElDialogMapTrack'
  import download from '@/components/ListDownload/index'
	import {
		mapGetters
	} from "vuex";

	export default {
		name: "SysUser",
		components: {
			download,
			ElDialogMapTrack
		},
		data() {
			return {
				trackShow: false, //轨迹弹窗控制
				options: {
					row: {},
					lineArr: ''
				},
				pickerOptionsStart: {
          disabledDate: time => {
            let endDateVal = this.tableend_time;
            if (endDateVal) {
              return time.getTime() > new Date(endDateVal).getTime();
            }
          }
        },
        pickerOptionsEnd: {
          disabledDate: time => {
            let beginDateVal = this.tableCreate_time;
            if (beginDateVal) {
              return (
                time.getTime() <
                new Date(beginDateVal).getTime() 
              );
            }
          }
        },
				bgWidth: '',
				tableCreate_time: '', //搜索选中时间戳
				tableend_time:'',
				aa: true,
				ampDisplay: false,
				dialogVisibleAdd: false,
				gStar: [0, 0, 0, 0, 0],
				gStartext: [false, false, false, false, false],
				charList: [],
				table: {
					url: '/order/indexOrder',
					where: {}
				}, // 表格配置
				choose: [], // 表格选中数据
				editForm: {}, // 表单数据
				checkForm: {},
				editRules: { // 表单验证规则
					phone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					},{
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, 
						message: '请输入正确的手机号', 
						trigger: 'blur',
					}],
					name: [{
						required: true,
						message: '请输入昵称',
						trigger: 'blur'
					}],
					sex: [{
						required: true,
						message: '请输入性别，1男2女',
						trigger: 'blur'
					}],
					home: [{
						required: true,
						message: '请输入家乡名称，对应aid',
						trigger: 'blur'
					}],

					money: [{
						required: true,
						message: '请输入余额',
						trigger: 'blur'
					}],

					losemoney: [{
						required: true,
						message: '请输入已提现/使用余额',
						trigger: 'blur'
					}],

				},
				dialogTableVisibleCheck: false,
				dialogTableVisibleChatRecord: false,
				provArr: [],
				cityArr: [],
				districtArr: [],
				audioArr: [],
				urgentList: [],
				imageUrl: false,
				headImg: '',
				zoom: 12,
				center: [104.07, 30.67],
				ampSrc: '',
				driverInfo: {},
				orderInfo: {},
				audio: '',
				earningsRecord: {},
				time:[],
				value: '',
				overOrderList: {},
				overOrderShow: false,	//控制手动结束填写原因
				finish: '',
			}
		},
		created() {
			this.$http.get('/common/province_list').then(res => {
				var data = JSON.parse(res.data)
				this.provArr = data
			})
			this.$http.post('/driver/earnings_record', {
				type: 1
			}).then(res => {
				this.earningsRecord = res.data.data
			})

			var url = this.$route.query.orderId
			if (url != undefined && url != '') {
				this.dialogTableVisibleChatRecord = true
				this.getChat(url)
			} else {
				this.dialogTableVisibleChatRecord = false
			}
		},
		computed: {
			...mapGetters(["permission"]),
		},

		mounted() {},
		methods: {
			//手动结束
			overOrder(row){
				this.overOrderList = row;
				this.overOrderShow = true;
			},
			nopaddingCell(val) {
				if (!val.column.label) {
					return "";
				}
				if (val.column.label.indexOf("订单状态") == -1) {
					return ""
				}
				
				if (!val.row.status_name) {
					return "";
				}
				let e = val.row.status_name;
				
				if(!e) {
					return "nopaddingCell"
				}else if(e.indexOf('已完成') != -1 || e.indexOf('已转单') != -1 ) {
					return 'cellDone'
				}else if(e.indexOf('进行中') != -1) {
					return 'cellProcess'
				}else if(e.indexOf('已取消') !=-1 || e.indexOf('已失效') != -1) {
					return 'cellCancel'
				}else if(e.indexOf('待接单') != -1) {
					return 'cellWait'
				}else if(e.indexOf('乘客取消') != -1){
					return 'cellCancel'
				}else if(e.indexOf('司机取消') != -1){
					return 'cellCancel'
				}else if(e.indexOf('空驶补偿') != -1){
					return 'cellCancel'
				}
				
				return "nopaddingCell";
			},
			birthdayChange(){
				console.log('value',this.value);
				if (this.value != null) {
					this.$set(this.table.where,'create_time', this.value)
					// this.table.where.start_day = this.tableTime[0]
					// this.table.where.end_day = this.tableTime[1]
				} else {
					this.table.where.create_time = []
				}
				//var time = [];
//				if(this.table.where.create_time.length >0){
				// 	this.time[0]=this.tableCreate_time
				// 	this.table.where.create_time = this.time;
				// console.log('value',this.table.where.create_time);

//				}else{
//					(this.table.where.create_time).unshift(this.tableCreate_time)
//				}
				//console.log(this.tableCreate_time)
				//console.log(this.table.where.create_time)
			},
			birthdayChange1(){
				//if(this.table.where.create_time.length >0){
					this.time[1]=this.tableend_time
					this.table.where.create_time = this.time;
				//console.log(this.table.where.create_time)
					
//				}else{
//					(this.table.where.create_time).push(this.tableend_time)
//				}
//				(this.table.where.create_time).push(this.tableend_time)
			},
			// 导出数据参数
			exportOrderParams() {
				const query = this.table.where
        //console.log(query)
				// const params = this.params
				const selectionIds = this.choose.map(item => {
					return item.id
				})

				return {
					ids: selectionIds,
					...query
				}
			},
			hanlePicture(src) {
				this.ampDisplay = true
				this.ampSrc = src
			},
			handleClose() {
				this.ampDisplay = false
			},
			closeDialog() {
				var middlePlay = document.querySelectorAll('.pasuVideo')
				for (let i = 0; i < middlePlay.length; i++) {
					middlePlay[i].pause()
					middlePlay[i].currentTime = 0
				}
			},
			handlePlay(url, id, index) {
				let that = this
				var middlePlay = document.querySelectorAll('.pasuVideo')
				for (let i = 0; i < middlePlay.length; i++) {
					middlePlay[i].pause()
					middlePlay[i].currentTime = 0
				}
				var middle = 'middle' + index
				middlePlay = this.$refs[middle][0]
				middlePlay.play()
				for (let i = 0; i < this.charList.length; i++) {
					if (i == index) {
						this.charList[index].isPlay = false
						middlePlay.addEventListener('ended', function() {
							that.charList[index].isPlay = true
						})
					} else {
						that.charList[i].isPlay = true
					}
				}
			},
			dropClick(command, row) {
				if (command === 'remove') { //删除
					this.remove()
				} else if (command === 'chat') { //紧急联系人
					this.handleChat(row)
				} else if (command === 'getChat') { //聊天记录
					this.getChat(row)
				} else if (command === 'track') { //聊天记录
					this.viewTrack(row)
				}
			},
			save() {},
			// 聊天记录
			getChat(id) {
        let that=this;
				const loading = this.$loading({
					lock: true,
					target: document.querySelector(".records")
				});
				this.$http.post('/order/chat_log', {
					id: id
				}).then(res => {
					console.log('获取聊天记录的res',res);
					var data = res.data
					loading.close()
					data.log.forEach(function(item) {
            item.content=that.$util.jsonToObject(item.content);
            item.isTimeShow = 1
						if (item.content.S == 21) {
              item.content.P=that.$util.jsonToObject(item.content.P);
							item.isPlay = true
							if (item.content.P.LONG == 1) {
								item.bgWidth = '8rem'
							}
							item.bgWidth = 8 + 0.05 * (parseInt(item.content.P.LONG) - 1) + 'rem'
						}
						if (item.content.S == 26) {
							item.content.P.POINT = item.content.P.POINT.split(',')
							//console.log(item.content.P.POINT)

						}
					});
					for (var i = 0; i < data.log.length; i++) {
						for (var j = i + 1; j < data.log.length - 1; j++) {
							if (data.log[i].create_time == data.log[j].create_time) {
								data.log[j].isTimeShow = 0 //不显示时间
							} else {
								data.log[j].isTimeShow = 1
							}
						}
					}
					this.charList = data.log
					this.driverInfo = data.driver
					this.orderInfo = data.order_info
				})

			},
			//查看轨迹
			viewTrack(row){
				const loading = this.$loading({
					lock: true
				});
				console.log('row',row);
				this.$http.post('/driver/getOneTrajectory', {
					order_id: row.order_id
				}).then(res => {
					loading.close();
					this.options.row = row;
					this.options.lineArr = res.data.data.driver_place;
					this.trackShow = true;
				})
			},
      //重置
      reset_search(){
		    this.value = '';
        this.$refs.childDownload.clearWhere();
        this.table.where={source:1}
        this.tableCreate_time = '';
        this.tableend_time = '';
        this.$refs.table.reload()
    
      },
			handleChat(row) {
				this.dialogTableVisibleChatRecord = true
				this.getChat(row.id)
			},
			/***选择省**/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				var pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.cityArr = data
					// //console.log(this.cityArr)
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
				})
			},
			/***选择市**/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				var cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},
			/***查看用户信息**/
			handlecheck(row) {
				this.dialogTableVisibleCheck = true
				this.checkForm = row;
			},

			/*** 刪除(批量刪除)*/
			remove(row) {
				if (!row) { // 批量删除
					if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
					let ids = this.choose.map(d => d.id);
					this.$confirm('确定要删除选中的订单吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/order/delete', {
							id: ids
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					}).catch(() => 0);
				} else { // 单个删除
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/order/delete', {
						id: row.id
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				}
			},
			/*** 手动结束确定*/
			order_status_update(row) {
				// this.$confirm('确定完成此订单?', '提示', {
				// 	type: 'warning'
				// }).then(() => {
				// 	const loading = this.$loading({
				// 		lock: true
				// 	});
				if(this.finish){
					this.$http.post('/order/order_status_update', {
						id: row.id,
            			order_id:row.order_id,
						finish: this.finish,
					}).then(res => {
						this.overOrderShow = false;
						// loading.close();
						this.finish = ''
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						this.overOrderShow = false;
						this.finish = ''
						// loading.close();
						this.$message.error(e.message);
					});
				} else {
					this.$message('请填写手动结束原因');
				}
				// }).catch(() => 0);
			},
			exportExcel() {
				let info = JSON.parse(JSON.stringify(this.table.where));
				//console.log('order data export');
				this.$http
					.get("/order/exportExcelOrder", info)
					.then((res) => {
						let data = res.data;
						if (data.code == 0) {
							// 下载文件
							window.location.href = data.data;
							this.$message({
								type: "success",
								message: "导出成功",
							});
						} else {
							this.$message.error("导出失败");
						}
					})
			},
		}
	}
</script>

<style scoped>
.bgGreen {
	background: #89B929 !important;
	color: #fff;
}

.ele-block .el-upload-dragger {
	width: 100%;
}

.changeStyle {
	width: 150px;
	margin-right: 30px;
}

.el-upload-list el-upload-list--text {
	display: none !important
}

.el-form-item-label {
	width: 20%;
	text-align: left
}

.disCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}

.mr-10 {
	margin-right: 10px;
}

.el-date-editor /deep/.el-input,
.el-date-editor /deep/.el-input__inner {
	width: 70% !important;
}

/deep/ .ampBox img {

	max-width: 370px;
	top: 15%;
	left: 50%;
	position: fixed;
}

/deep/.el-dialog {
	margin-top: 0px !important;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	/* margin-top: 60px !important; */
	max-width: 1200px;
}

.el-col {
	border-radius: 4px;
}

.bg-purple-dark {
	background: #99a9bf;
}

.bg-purple {
	background: #fff;
	height: 160px;
}

.bg-purple-light {
	background: #e5e9f2;
}

.grid-content {
	border-radius: 4px;
	min-height: 36px;
}

.row-bg {
	padding: 10px 0;
	background-color: #f9fafc;
}

.smsInner {
	border-bottom: 1px solid #ddd;
	padding-bottom: 14px;
}

.el-date-editor /deep/.el-input,
.el-date-editor /deep/.el-input__inner {
	width: 100% !important;
}

/deep/.el-table .cell {
	display: flex;
}
</style>
